import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import UTILS from '@/store/modules/UtilityModule';

import { extractDomain } from '@/plugins/domainDetection';

import { Truck } from '@/types/truck';

dayjs.extend(utc);
dayjs.extend(timezone);

interface StringIndex {
  [key: string]: any;
}

function getAuthHeader(): Record<string, unknown> {
  const auth = { headers: {} as any };
  if (!localStorage || process.server) {
    return auth;
  }
  const token = localStorage.getItem('JStoken');
  if (token !== null) {
    auth.headers.Authorization = 'Bearer ' + token;
  }
  return auth;
}

function getBrowserData() : any {

	return {
		java: navigator.javaEnabled(),
		language: navigator.language,
		color_depth: screen.colorDepth,
		screen_height: screen.height,
		screen_width: screen.width,
		tz: (new Date()).getTimezoneOffset(),
		user_agent: navigator.userAgent,
		platform: (typeof navigator.platform !== 'undefined') ? navigator.platform : '',
	};
}

function userTokenIsValid(): boolean {
  if (!localStorage || process.server) {
    return true;
  }
  const token = localStorage.getItem('JStoken');
  const currentTimeStamp = new Date().getTime();

  if (!token) {
    return false;
  }

  const payload = token.split('.')[1]; // JStoken payload string
  const decodedPayload = JSON.parse(atob(payload)); // atob() decodes from Base64 to JSON
  const tokenExpiration = decodedPayload.exp * 1000; // Converting expiration value to timestamp

  if (tokenExpiration && currentTimeStamp <= tokenExpiration + 60) {
    return true;
  }

  localStorage.removeItem('JStoken');
  return false;
}

// Since we changed to auth v2 -> this handles users having v1 tokens/cookies stored and remove them.
function tokenIsDeprecated(): boolean {
  if (process.server) {
    return false;
  }

  try {
    if (typeof localStorage === 'undefined') {
      return false;
    }
    const token = localStorage.getItem('JStoken');
    if (!token) {
      return false;
    }

    const payload = token.split('.')[1]; // JStoken payload string
    const decodedPayload = JSON.parse(atob(payload)); // atob() decodes from Base64 to JSON
	return decodedPayload.sub === undefined || decodedPayload.sub === null;
  } catch (error) {
    console.error('Error checking token deprecation:', error);
    return false;
  }
}

function getUserFromToken(token?: string): string {
  if (!token && (!localStorage || process.server)) {
    return '';
  }
  const t = token !== undefined ? token : localStorage.getItem('JStoken');
  if (!t) {
    throw new Error('No token found in function: getUserFromToken');
  }
  const decoded = JSON.parse(atob(t.split('.')[1]));
  if (!decoded || !decoded.sub) {
    throw new Error('Could not extract user ID from JSToken');
  }
  return decoded.sub;
}

// Merge 2 objects overwriting only the changed values
function mergeObj(def: Record<string, unknown>, val: Record<string, unknown>) {
  const defKeys = Object.keys(def);
  const newKeys = Object.keys(val);
  const result: object = {};
  const keys = [...new Set([...defKeys, ...newKeys])];
  for (const k in keys) {
    const n = keys[k];
    if (typeof def[n] === 'object') {
      (result as any)[n] = mergeObj((def as any)[n], (val as any)[n]);
    } else {
      (result as any)[n] =
        val[n] !== undefined &&
        val[n] != null &&
        typeof val[n] === 'string' &&
        (val[n] as any).length > 0
          ? val[n]
          : def[n];
    }
  }
  return result;
}

function safariCheck(): boolean {
  const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  return isSafari;
}

function insertInString(str: string, index: number, value: string) {
  return str.substring(0, index) + value + str.substring(index);
}

function formatDate(date: string): string | null {
  const currentDomain = extractDomain(window.location.host);

  if (!date) {
    return null;
  }

  if (currentDomain === 'hu') {
    return dayjs(date).format('YYYY.MM.DD');
  }

  return dayjs(date).format('DD.MM.YYYY');
}

// stupid string replace approach to force at-timezone
function dateToAustrianTimezone(date: string): dayjs.Dayjs {
  // get austrian timezone for the date
  // pass full date since the timezoneoffset changes at 2 am
  const aut = dayjs(date).tz('Europe/Vienna').format();
  const aut_tz = aut.substring(19);

  const input = dayjs(date).format();
  const output = input.substring(0, 19) + aut_tz;

  const result = dayjs(output);

  return result;
}

// Clear the object passed as an argument without destroying its structure. Basically sets its values to null. Recursive.
function clearObjectValues(objToClear: Record<string, unknown>) {
  Object.keys(objToClear).forEach((param) => {
    if (
      objToClear[param] != null &&
      objToClear[param] !== undefined &&
      typeof objToClear[param] === 'object'
    ) {
      clearObjectValues(objToClear[param] as any);
    } else {
      (objToClear as Record<string, unknown>)[param] = null;
    }
  });
}

// prevent typing more than X max characters on an input - Best used with @keydown="inputLimit($event, <prop bound (v-model)>, number)"
function inputLimit(event: any, dataProp: string, max: number) {
  if (!dataProp || !event.key) {
    return;
  }

  const key_allowed = ['Backspace', 'Tab', 'Arrow'];
  const allowed = key_allowed.some((key) => event.key.includes(key));

  if (dataProp.length >= max && !allowed) {
    event.preventDefault();
  }
}

function triggerDownload(url: string, title: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = title;
  downloadLink.click();
}

function triggerRipple($el: any) {
  const ev = new Event('mousedown') as any;
  const offset = $el.getBoundingClientRect();
  ev.clientX = offset.left + offset.width;
  ev.clientY = offset.top + offset.height / 2;
  $el.dispatchEvent(ev);

  setTimeout(function () {
    $el.dispatchEvent(new Event('mouseup'));
  }, 300);
}

function getTruckImgLink(truck: Truck) {
  if (!truck || (truck._media && truck._media.length < 1)) {
    return '/img/truck_404.jpg'; // placeholder
  } else if (truck._media && truck._media.length > 1) {
    const imgList = truck._media;

    for (const i in imgList) {
      if (imgList[i as any].type === 'FRONT_DRIVER') {
        // front driver priority
        return imgList[i as any].link;
      }
    }
    return imgList[0].link; // Otherwise the first img
  }
}

function applyChanges(orig: any, changes: any) {
  const keys = Object.keys(orig);
  // loop through keys of orig
  for (const i in keys) {
    const k = keys[i];

    // skip if key does not exist in changes
    if (changes[k] === undefined) {
      continue;
    }

    if (orig[k] !== null && typeof orig[k] === 'object' && !Array.isArray(orig[k])) {
      applyChanges((orig as any)[k], (changes as any)[k]);
    } else {
      orig[k] = changes[k];
    }
  }
  return orig;
}

function gmapBoundsToMinMax(bounds: any) {
  if (bounds === null || bounds === undefined) {
    return null;
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return {
    minLat: sw.lat(),
    maxLat: ne.lat(),
    minLng: sw.lng(),
    maxLng: ne.lng(),
  };
}

function getVolume(h: number, w: number, l: number): number {
  return (h / 100) * (w / 100) * (l / 100);
}

function getLastSunday(year: number, month: number) {
  // type unsure
  const d = new Date(year, month + 1, 0); // last day of month
  const lastSunday = dayjs(d).date() - dayjs(d).day();
  // since sunday is index 0 ^ this gets the last sunday by substracting last month day index from total month days
  return dayjs(d).date(lastSunday).format('DD');
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms);
  });
}

function createPlaceholderImage(params: { width: number; height?: number; text?: string }): string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = params.width;
  canvas.height = params.height ?? params.width;
  if (context) {
    context.fillStyle = '#eeeeee';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = 'bold 30px sans-serif';
    context.fillStyle = '#bdbdbd';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(params.text ?? 'No picture', canvas.width / 2, canvas.height / 2);
  }

  return canvas.toDataURL();
}

function groupBy(list: any[], keyGetter: (item: any) => string): Map<string, any[]> {
  // Returns a map constructor containing
  const map = new Map<string, any[]>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function distanceBetweenCoordinatesInKm(params: {
  coordinates_1: { lat: number; lng: number };
  coordinates_2: { lat: number; lng: number };
}) {
  const { lat: lat1, lng: lng1 } = params.coordinates_1;
  const { lat: lat2, lng: lng2 } = params.coordinates_2;

  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  // Convert dist in Km
  dist = dist * 1.609344;
  return dist;
}

function formatPhone(phone: string): string {
  if (!phone) {
    return '';
  }

  let cty_code = phone.substring(0, 4);
  let prefix = '';
  let rest = '';
  let rest_cut_idx = 3; // index at which the rest is cut (last  spacing)

  let first_part_hu = '';
  let second_part_hu = '';

  switch (cty_code) {
    case '0043': // AT
      prefix = phone.substring(4, 7);
      rest = phone.substring(7);
      break;
    case '0049': // DE
      prefix = phone.substring(4, 7);
      rest = phone.substring(7);
      rest_cut_idx = 4;
      break;
    case '0042': // CZ
      cty_code = phone.substring(0, 5);
      prefix = phone.substring(5, 8);
      rest = phone.substring(8);
      break;
    case '0036': // HU
      cty_code = '+36';
      prefix = phone.substring(4, 5);
      first_part_hu = phone.substring(5, 8);
      second_part_hu = phone.substring(8);
      break;
    default:
      prefix = phone.substring(4, 7);
      rest = phone.substring(7);
      break;
  }

  if (cty_code === '+36') {
    return `${cty_code} ${prefix} ${first_part_hu} ${second_part_hu}`
  }

  if (cty_code.substring(0, 2) === '00') {
    cty_code = '+' + cty_code.substring(2);
  }

  rest =
    rest
      .match(new RegExp('.{0,' + rest_cut_idx + '}', 'g'))
      ?.join(' ')
      .trim() || '';
  return `${cty_code} ${prefix} ${rest}`;
}

function priceWithCommas(price: number) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function getBookingStartingPrice(prices: any, features: any): number {
  const default_price = prices.truck1;

  if (!features['6_hour_blocks']) {
    return default_price;
  }
  // if there are slot entries in the offtime array return the offtime price
  if (features.offtime_slots && features.offtime_slots.length > 0) {
    return prices.truck1_6off;
  }
  // otherwise return the 6 hour promo price
  return prices.truck1_6;
}

// Basically a hacky way of showing '*' in the user input when the input isn't of type="password"
function getHiddenPasswordAndRealPassword(params: {
  input_event: Event;
  pw_to_hide: string;
  real_pw: string;
}): { new_pw_to_hide: string; new_real_pw: string } {
  // The input_event need to be the @keyup from the v-text-field
  // pw_to_hide is the prop bound to :value from the v-text-field
  // real_pw is just an independant data prop
  let realValue = params.real_pw;

  const inputField = params.input_event.target as any;
  const cursorPosition = inputField.selectionStart;
  const currentValue = inputField.value;

  // Determine what changed (added/removed characters)
  if (currentValue.length > realValue.length) {
    // Characters added
    const addedText = currentValue.slice(
      cursorPosition - (currentValue.length - realValue.length),
      cursorPosition
    );
    realValue =
      realValue.slice(0, cursorPosition - addedText.length) +
      addedText +
      realValue.slice(cursorPosition - addedText.length);
  } else {
    // Characters removed
    const removedLength = realValue.length - currentValue.length;
    realValue =
      realValue.slice(0, cursorPosition) + realValue.slice(cursorPosition + removedLength);
  }

  return {
    new_pw_to_hide: '*'.repeat(realValue.length),
    new_real_pw: realValue,
  };
}

function getStaticGmapStyle(styles: any): string {
  // Create string for google static map url based on our mapStyle object.
  let i = '';
  const n = styles;
  for (const t in n) {
    void 0 === n[t].featureType && (n[t].featureType = 'all');
    let e = 'style=feature:' + n[t].featureType;
    void 0 !== n[t].elementType && (e += '|element:' + n[t].elementType);
    for (let o in n[t].stylers) {
      let i = n[t].stylers[o],
        a = Object.keys(i)[0];
      void 0 !== a && (e += '|' + a + ':' + i[a].toString().replace('#', '0x'));
    }
    i += '&' + encodeURIComponent(e);
  }
  return i;
}

function tabChangeTitleListener() {
  // Title handling with unfoxus on tab
  let visibilityState: any, visibilityChange;
  if (typeof document.visibilityState !== 'undefined') {
    visibilityState = 'visibilityState';
    visibilityChange = 'visibilitychange';
  } else if (typeof (document as any).mozVisibilityState !== 'undefined') {
    visibilityState = 'mozVisibilityState';
    visibilityChange = 'mozvisibilitychange';
  } else if (typeof (document as any).msVisibilityState !== 'undefined') {
    visibilityState = 'msVisibilityState';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof (document as any).webkitVisibilityState !== 'undefined') {
    visibilityState = 'webkitVisibilityState';
    visibilityChange = 'webkitvisibilitychange';
  }

  const unfocusTitle = 'Vergiss nicht deine Buchung abzuschließen';
  let previousTitle = '';

  if (visibilityChange != null && visibilityState != null) {
    document.addEventListener(visibilityChange, function () {
      let booking = null;
      if (!localStorage) {
        return;
      }
      booking = JSON.parse(localStorage.getItem('booking') as string);
      if (booking && booking.truck) {
        if ((document as any)[visibilityState] === 'visible') {
          if ((document.title === UTILS.document_unfocus_title || unfocusTitle) && previousTitle) {
            document.title = previousTitle;
          }
        } else if ((document as any)[visibilityState] === 'hidden') {
          previousTitle = document.title;
          document.title = UTILS.document_unfocus_title ?? unfocusTitle;
        }
      }
    });
  }
}

export {
  getAuthHeader,
  getBrowserData,
  userTokenIsValid,
  tokenIsDeprecated,
  getUserFromToken,
  applyChanges,
  triggerRipple,
  safariCheck,
  insertInString,
  mergeObj,
  triggerDownload,
  formatDate,
  createPlaceholderImage,
  dateToAustrianTimezone,
  clearObjectValues,
  inputLimit,
  getTruckImgLink,
  gmapBoundsToMinMax,
  getLastSunday,
  getVolume,
  wait,
  groupBy,
  distanceBetweenCoordinatesInKm,
  formatPhone,
  priceWithCommas,
  getBookingStartingPrice,
  getHiddenPasswordAndRealPassword,
  getStaticGmapStyle,
  tabChangeTitleListener,
};
