
import Vue from 'vue';

import LocaleSwitcher from '@/components/general/utils/LocaleSwitcher.vue';

import { routesNames, getRoutePath } from '@/router';
import { formatPhone } from '@/_helpers/misc_helper';

import USER from '@/store/modules/UserModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';
import { GACustomEvent } from '~/types/misc_data';
import currentDomain from '@/mixins/currentDomain';
import globalAws from '@/mixins/globalAws';
import { IPartner } from '@/types/misc_data';
import browserCheck from '@/mixins/browserCheck';

export default Vue.extend({
	name: 'NavBarTop',

	components: { LocaleSwitcher },

	mixins: [currentDomain, browserCheck],

	data() {
		return {
			burger_drawer: false,
			routesNames: routesNames,
			getRoutePath: getRoutePath,
			awsPath: globalAws,
      isPartner: false
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,
		USER: () => USER,
		CART: () => CART,
		UTILS: () => UTILS,

		isLoggedIn(): boolean {
			return this.USER.is_logged_in;
		},

		domain(): string {
			return this.$getDomain();
		},

		isProd(): boolean {
			return process.env.NODE_ENV === 'production';
		},

		showContactForm(): boolean {
			return false;
			// return !['at', 'de', 'cz', 'hu'].includes(this.$getDomain());
		},

		mobileSize(): boolean {
			return this.$vuetify.breakpoint.mdAndDown;
		},

		advantageAnchorId(): string {
			return (this.$t('general.advantages') as any).normalize('NFD').replace(/\p{Diacritic}/gu, '');
		},

		pricesAnchorId(): string {
			return (this.$t('general.prices') as any).normalize('NFD').replace(/\p{Diacritic}/gu, '');
		},

		phoneFee(): number {
			const fee = this.MISC_DATA.data_per_bu.phone_fee;
			return fee || 0;
		},

		phoneNumber(): string
		{
			return this.MISC_DATA.basedata.bu_info.contact.phone.paid ?? ''
		},

		showBurgerMenu(): boolean {
			const r = this.$route.name;
			return r !== routesNames.upsells_1 && r !== routesNames.upsells_2 && r !== routesNames.info && r !== routesNames.summary && r !== routesNames.checkout;
		},

		isLongTermRentAvailable(): boolean {
			return this.MISC_DATA.data_per_bu?.long_term_rent_allowed;
		}
	},

	methods: {
		formatPhone(input: string): string {
			return formatPhone(input);
		},
		goToLogin(): void {
			const no_redirect = [routesNames.signup].includes(this.$route.name as routesNames);
			if (this.$route.name !== routesNames.login) {
				this.$router.push({ path: getRoutePath(routesNames.login, this.$getDomain()), query: no_redirect ? undefined : { redirect: this.$route.fullPath } });
			}
		},

		phoneClicked() {
			this.$tracking.custom_event(GACustomEvent.phone_on_navbar);
		},

		async logout() {
			this.$logger.console({ message: 'User logout' });

			const res = await this.USER.LOGOUT();

			this.burger_drawer = false;

			if (res === false && this.$route.name !== routesNames.home) {
				// Is that still a thing ??
				this.$logger.console({ message: 'Logout res in navbar returned falsy (booking created/error) clear everything and back home' }); // No idea what this is and it does nothing but redirecting

				this.$router.replace({ name: routesNames.home });
			}
		},

		facebookRedirect(): void {
			window.open('https://www.facebook.com/123transporter.at/');
		},

		linkedinRedirect(): void {
			window.open('https://www.linkedin.com/company/123MISC_DATA-mobility-gmbh');
		},

		updateVH() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		},

	  async openRedirectionDialog() {
      const tidioUrl: string | null = this.MISC_DATA.basedata.live_chat_url;

      if (this.isDeDomain) {
        if (
          !(await this.$dialogs.confirm(this.$t('cust.cookie.tidio.text') as string, this.$t('cust.cookie.tidio.title') as string, {
            color: 'grey--text text--darken-3',
            right_btn_text: this.$t('actions.next') as string,
            left_btn_text: this.$t('actions.abort') as string
          }))
        ) {
          return;
        }
      }

      if (tidioUrl) {
        window.open(tidioUrl, '_blank');
      }
    },
	},

	watch: {
    '$route.path': {
      handler(newPath) {
        if (newPath) {
          const partners = this.MISC_DATA.partners;
		      const landingPages = MISC_DATA.landing_pages;

          const partnerRoutes = partners.map((p: IPartner) => {
            return p.route_name;
          });

          const isPartnerRoute = partnerRoutes.includes(this.$route.name as routesNames);
		      const isSingleLocationPage = landingPages.some(obj => obj.url === this.$route.path);


          if (isPartnerRoute || isSingleLocationPage) {
            this.isPartner = true;
          } else {
            this.isPartner = false;
          }
        }
      },
      immediate: true
    }
  },

	mounted() {
		if (this.isSafari) {
			this.updateVH();
		}
	}
});
