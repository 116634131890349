
import Vue from 'vue';
import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';

import ContactForm from '@/components/general/utils/ContactForm.vue';
import { getRoutePath, routesNames } from '@/router';

import currentDomain from '@/mixins/currentDomain';
import cookieConsent from '@/mixins/cookieConsent';

export default Vue.extend({
	name: 'FooterMain',

	components: { ContactForm },
	mixins: [currentDomain, cookieConsent],

	data() {
		return {
			expandedPanels: [] as string[],
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,
		UTILS: () => UTILS,

		dialogContactForm(): boolean {
			return this.$route.query.dialog === 'dialog-contact-form';
		},

		companyName(): string {
			return this.MISC_DATA.basedata.bu_info.name ? this.MISC_DATA.basedata.bu_info.name : (this.$t('general.company_name') as string);
		},

		showContactForm(): boolean {
			return false;
			// return !['at', 'de', 'cz', 'hu'].includes(this.$getDomain());
		},

		footer() {
			const domain = this.$getDomain();
			return [
				{
					heading: this.$t('general.enterprise'),
					links: [
						{
							title: this.$t('general.about_us'),
							route: { path: getRoutePath(routesNames.about, domain) }
						},
						{
							title: this.$t('general.career'),
							route: { path: getRoutePath(routesNames.jobs, domain) }
						},
						{
							title: this.$t('general.sustainability'),
							route: { path: getRoutePath(routesNames.sustainability, domain) }
						},
						{
							title: this.$t('general.press.title'),
							route: { path: getRoutePath(routesNames.press, domain) }
						},
						{
							title: this.$t('general.fleet_partner'),
							route: { path: getRoutePath(routesNames.fleet_partner, domain) },
							hide: this.isCzDomain || this.isSkDomain
						},
						{
							title: this.$t('general.location_partner'),
							route: this.isCzDomain ? 'https://location.123-transporter.cz/' : { path: getRoutePath(routesNames.location_partner, domain) },
							raw: domain === 'cz'
						}
					]
				},
				{
					heading: this.$t('general.rental'),
					links: [
						{
							title: this.$t('general.faq_short'),
							route: { path: getRoutePath(routesNames.faq, domain) }
						},
						{
							title: this.$t('general.all_vans'),
							route: { path: getRoutePath(routesNames.all_trucks, domain) }
						},
						{
							title: this.$t('usage_rules.footer_link'),
							route: { path: getRoutePath(routesNames.usage_rules, domain) }
						},
						{
							title: this.$t('general.contact'),
							route: { path: getRoutePath(routesNames.contact, domain) }
						},
						{
							title: this.$t('general.our_app'),
							route: { path: getRoutePath(routesNames.our_app, domain) }
						},
						{
							title: this.$t('footer.damage_page'),
							route: { path: getRoutePath(routesNames.damage_info, domain) }
						}
					]
				},
				{
					heading: this.$t('general.legal'),
					links: [
						{
							title: this.$t('general.imprint'),
							route: { path: getRoutePath(routesNames.imprint, domain) }
						},
						{
							title: this.$t('general.data_protection'),
							route: { path: getRoutePath(routesNames.privacy_policy, domain) }
						},
						{
							title: this.$t('general.data_protection_phone'),
							route: { path: getRoutePath(routesNames.privacy_policy_phone, domain) },
							hide: true
						},
						/* hide: !['at', 'de'].includes(this.$getDomain()) || this.MISC_DATA.basedata.data_protection_phone === null */
						{
							title: this.$t('general.conditions_short'),
							route: { path: getRoutePath(routesNames.agb, domain) },
              newTab: false
						},
						{
							title: this.$t('general.payment_methods'),
							route: { path: getRoutePath(routesNames.payment_methods, domain) },
							hide: this.isCzDomain || this.isHuDomain || this.isSkDomain
						},
						{
							title: this.$t('general.resolution_platform'),
							route: 'https://ec.europa.eu/odr',
							raw: true
						},
						{
							title: this.$t('general.cookie_settings'),
							route: '#CCM.openWidget',
							hide: !(this.isDeDomain || this.isCzDomain),
							raw: true
						}
					]
				}
			];
		},
	},

	methods: {
		facebookRedirect() {
			window.open('https://www.facebook.com/123transporter.at/');
		},

		linkedinRedirect() {
			window.open('https://www.linkedin.com/company/123-shared-mobility-gmbh');
		},

		instagramRedirect() {
			window.open('https://www.instagram.com/123_transporter/');
		},

		togglePanel(heading: string) {
			const index = this.expandedPanels.indexOf(heading);
		
			if (index === -1) {
				this.expandedPanels.push(heading);
			} else {
				this.expandedPanels.splice(index, 1);
			}
		},

		isPanelExpended(heading: string) {
			return this.expandedPanels.includes(heading);
		},

    async openRedirectionDialog() {
      const tidioUrl: string | null = this.MISC_DATA.basedata.live_chat_url;

      if (this.isDeDomain) {
        if (
          !(await this.$dialogs.confirm(this.$t('cust.cookie.tidio.text') as string, this.$t('cust.cookie.tidio.title') as string, {
            color: 'grey--text text--darken-3',
            right_btn_text: this.$t('actions.next') as string,
            left_btn_text: this.$t('actions.abort') as string
          }))
        ) {
          return;
        }
      }

      if (tidioUrl) {
        window.open(tidioUrl, '_blank');
      }
    }
	}
});
